import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Alert,
    Table
} from 'react-bootstrap'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../../Biometric/StatesComponents/LoadingHandler'


export function TicketCount() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const userId = sessionStorage.getItem('token')

    const [ticketList, setTicketList] = useState<any[]>()

    const [totalCount, setTotalCount] = useState(0)

    const [loader, setLoader] = useState(false)


    const distributionGates = [
      {
        gate: '1, 2',
        entrance: '1, 2',
        id: '1, 2',
        count: 0
      },{
        gate: 'Memorial',
        entrance: 'Memorial',
        id: 'Memorial',
        count: 0
      },{
        gate: '24',
        entrance: '24',
        id: '24',
        count: 0
      },{
        gate: '17',
        entrance: '17',
        id: '17',
        count: 0
      },{
        gate: '28',
        entrance: '28',
        id: '28',
        count: 0
      },{
        gate: '13,14 & 15',
        entrance: '13,14 & 15',
        id: '13,14 & 15',
        count: 0
      },{
        gate: '19',
        entrance: '19',
        id: '19',
        count: 0
      },{
        gate: '7, 8',
        entrance: '7, 8',
        id: '7, 8',
        count: 0
      },{
        gate: '23',
        entrance: '23',
        id: '23',
        count: 0
      },{
        gate: '25',
        entrance: '25',
        id: '25',
        count: 0
      },{
        gate: '22',
        entrance: '22',
        id: '22',
        count: 0
      },{
        gate: '27',
        entrance: '27',
        id: '27',
        count: 0
      },{
        gate: '16',
        entrance: '16',
        id: '16',
        count: 0
      },{
        gate: '26',
        entrance: '26',
        id: '26',
        count: 0
      }
    ]

    const setGateName = (ticketsCount: any) => {

        let tempCount = 0
        ticketsCount.map((item: any) => {
            const currentIndex = distributionGates.findIndex(obj => obj.id === item.gate_id)
            distributionGates[currentIndex].count = item.count
            tempCount += Number(item.count)
        })

        setTotalCount(tempCount)
        setTicketList(distributionGates)
    }


    const onSubmit = (data: any) => {
        if (!data.affectation || data.affectation.length < 3) {
            return setError('affectation', {
                type: 'manual',
                message: 'Você precisa preencher ao menos 1 campo!'
            })
        }

        setLoader(true)
        setTicketList([])

        fetch(`${BASE.api_game.base_url}${BASE.api_game.get_game_tickets_count}/${data.affectation}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoader(false)
                setGateName(response)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">Tickets Baixados Bepass</Card.Title>
            </Card.Header>
            <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        {loader ? (
                            <LoadingHandler />
                        ) : (

                            <Col>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    placeholder="Código da partida (ex: 409)"
                                    className="mb-3 f-10"
                                    {...register('affectation', { required: true })}
                                />
                                {errors.affectation && (
                                    <span className="label-error">
                                        Você precisa informar um código
                                    </span>
                                )}
                            </Col>
                        )}

                    </Row>
                    <Row>
                        <Col className="xs-12">
                            <Button type="submit" className="mb-3 w-100 ">
                                Procurar
                            </Button>
                        </Col>
                    </Row>
                    {ticketList && ticketList.length > 0 &&
                        <Row>
                            <Col>
                                <Card>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Portão</th>
                                                <th className="text-right">({totalCount}) Ingressos</th>
                                            </tr>
                                        </thead>
                                        <tbody className="access-list">

                                            {ticketList.map((ticket: any, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {ticket.gate}
                                                    </td>
                                                    <td>
                                                        {ticket.id} - {ticket.entrance}
                                                    </td>
                                                    <td className="text-right">
                                                        {ticket.count}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card></Col>
                        </Row>
                    }
                </form>
            </Card.Body>
        </Card >
    )
}
