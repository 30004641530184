import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table
} from 'react-bootstrap';
import { BASE } from '../../basedata/constants';
import { TerminalListItem } from '../components/terminalListItem';
import './terminals.sass';

function TerminalsPage() {
  const [terminalList, setTerminalList] = useState<any>([]);

  const getTerminalList = () => {
    fetch(`${BASE.api.base_url}${BASE.api.terminals}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
    })
    .then((res) => res.json())
    .then((response) => {
      const validTerminals = response.filter((item: any) => item != null);
      setTerminalList(validTerminals);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    getTerminalList();
  }, []);

  return (
    <>
      <div className="game-tickets-header">
        <Row>
          <Col xl={4}>
            <Card.Title as="h5">Catracas Mapeadas</Card.Title>
          </Col>
        </Row>

        <Table responsive striped className='terminal-list'>
          <thead>
            <tr>
              <th>Ping</th>
              <th>Versão</th>
              <th>Portão Onpremisse</th>
              <th>Tag da Catraca</th>
              <th>IP Dispositivo Facial</th>
              <th>IP Raspbery</th>
              <th>MacAddress</th>
              <th>Portão</th>
              <th>Setor</th>
              <th className='text-center'>Marca Dispositivo Facial</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {terminalList.length > 0 && terminalList.map((item: any, index: any) => (
              <TerminalListItem terminalData={item} key={index} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default TerminalsPage;
